import * as React from 'react'
import { QueryClientProvider as QCProvider, QueryClient } from '@tanstack/react-query'
import { useState } from 'react'


const useConstant = (fn) => useState(fn)[0]

function QueryClientProvider({ children }) {
  const queryCache = useConstant(() => {
    const client = new QueryClient({
      defaultOptions: {
        queries: {
          useErrorBoundary: true,
          refetchOnWindowFocus: false,
          retry(failureCount, error) {
            if (error.status === 404) return false
            else if (failureCount < 2) return true
            else return false
          },
        },
        mutations: {
          onError: (err, variables, recover) =>
            typeof recover === 'function' ? recover() : null,
        }
      }
    })
    window.__devtools?.setQueryClient?.(client)
    return client
  })
  return (
    <QCProvider client={queryCache}>
      {children}
    </QCProvider>
  )
}

export { QueryClientProvider }
