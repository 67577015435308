/** @jsxImportSource @emotion/react **/
import tw from 'twin.macro'
/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, keyframes } from '@emotion/react'

import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { Link as RouterLink, NavLink, useMatch } from 'react-router-dom'
import styled from '@emotion/styled/macro'
import * as colors from '../styles/colors'
import * as mq from '../styles/media-queries'
import { Dialog as ReachDialog } from '@reach/dialog'
import { FaSpinner } from 'react-icons/fa'
import PreviewPassword from 'assets/previewPassword.svg';
import TriangleTooltip from './Tooltip';

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
})

const CircleButton = styled.button({
  borderRadius: '30px',
  padding: '0',
  width: '40px',
  height: '40px',
  lineHeight: '1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: colors.base,
  color: colors.text,
  border: `1px solid ${colors.gray10}`,
  cursor: 'pointer',
})

const BookListUL = styled.ul({
  listStyle: 'none',
  padding: '0',
  display: 'grid',
  gridTemplateRows: 'repeat(auto-fill, minmax(100px, 1fr))',
  gridGap: '1em',
})

const Spinner = styled(FaSpinner)({
  animation: `${spin} 1s linear infinite`,
})
Spinner.defaultProps = {
  'aria-label': 'loading',
}

const buttonVariants = {
  primary: {
    background: colors.primary,
    color: colors.base,
    ':hover': {
      background: colors.primaryDarker
    }
  },
  secondary: {
    background: colors.base,
    color: colors.anchorText,
    border: '1px solid #E2E8F0',
    ':hover': {
      // border: `1px solid ${colors.primaryDarker}`,
      ...tw`border-slate-300`
    }
  },
  dangerHover: {
    ':hover': {
      border: `1px solid ${colors.danger}`,
      color: colors.danger
    }
  },
  breadcrumb: {
    color: colors.anchorText,
    background: colors.base,
    border: '1px solid #E2E8F0',
    borderRadius: '4px',
    margin: 0,
    padding: '4px 10px'
  }
}
const BasicButton = styled.button`
    padding: 8px 12px;
    border: 0;
    box-shadow: 0px 1px 2px rgba(15, 23, 42, 0.08);
    line-height: 22px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
  ${({ variant = 'primary' }) => {
    if (Array.isArray(variant)) {
      return variant.reduce(
        (accumulator, currentVariant) => ({ ...accumulator, ...buttonVariants[currentVariant] }),
        {}
      );
    }
    return buttonVariants[variant]
  }}
`
const Button = React.forwardRef((props, ref) => <BasicButton ref={ref} className='btn' {...props} />)

const BasicButtonSpan = styled.ul({
  padding: '8px 12px',
  border: '0',
  boxShadow: '0px 1px 2px rgba(15, 23, 42, 0.08)',
  lineHeight: '22px',
  borderRadius: '3px',
  fontSize: '14px',
  fontWeight: 500,
  background: colors.primary,
  cursor: 'pointer',
  color: colors.base,
  ':hover': {
    background: colors.primaryDarker
  }
})

const inputStyles = {
  border: '1px solid #f1f1f4',
  background: '#f1f2f7',
  padding: '8px 12px',
}

// const Input = styled.input({ borderRadius: '3px' }, inputStyles)
const Input = React.forwardRef(({ type, withTogglePassword, className = '', isError, ...props }, ref) => {
  const [togglePassword, setTogglePassword] = React.useState(false);
  if (type === 'password' && withTogglePassword) {
    return (
      <div tw='relative flex items-center'>
        <input ref={ref} className={`form-input w-full ${className}`} type={togglePassword ? 'text' : type} {...props} />
        <span tw='absolute right-2 cursor-pointer' aria-label="toggle password" onClick={() => setTogglePassword(!togglePassword)}>
          <img src={PreviewPassword} alt="toggle password" />
        </span>
      </div>
    )
  }
  if (type === 'textarea') {
    return (
      <textarea ref={ref} className={`form-input w-full ${isError ? '!border-danger-300' : ''} ${className}`} {...props} />
    )
  }
  return (
    <input ref={ref} className={`form-input w-full ${isError ? '!border-danger-300' : ''} ${className}`} type={type} {...props} />
  )
})
const FileInput = React.forwardRef(({ className = '', id, label, hasValue, isError, resetValue, ...props }, ref) => {

  return (
    <span className={`flex flex-col w-full ${className}`}>
      <span css={{ color: colors.anchorText }} className={`text-sm mb-2 font-medium pointer-events-none${isError ? '!text-danger-300' : ''}`}>{label}</span>
      <div>
        <label>
          <input ref={ref} className={'hidden'} {...props} id={id} type='file' />
          {/* <Button  >Choose File</Button> */}
          <BasicButtonSpan className='btn'>Choose File</BasicButtonSpan>
        </label>
        <span css={{ color: colors.anchorText }} className='ml-5 text-sm font-medium pointer-events-none truncate'>{hasValue?.[0]?.name || 'No file chosen'}</span>
        {hasValue?.length ? (
          <span className='cursor-pointer ml-2' onClick={() => resetValue()}>X</span>
        ) : null}
      </div>
    </span>
  )
})

const SidebarNavItem = ({ path, text, notificationCount, selected, icon, ...props }) => {
  const match = useMatch(path)

  return (
    <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${match && 'bg-slate-900'}`} {...props}>
      <NavLink
        end
        to={`/${path}`}
        className={`block text-slate-200 hover:text-white truncate transition duration-150 ${match && 'hover:text-slate-200'
          }`}
      >
        <div tw="flex items-center justify-between">
          <div tw='grow flex items-center'>
            <TriangleTooltip label={text}>
              <div>
                {icon}
              </div>
            </TriangleTooltip>

            <span tw="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
              {text}
            </span>
          </div>
          {/* Badge */}
          {notificationCount ? (
            <div tw="flex flex-shrink-0 ml-2">
              <span tw="inline-flex items-center justify-center h-5 text-xs font-medium text-white bg-indigo-500 px-2 rounded">{notificationCount}</span>
            </div>
          ) : null}
        </div>
      </NavLink>
    </li>
  )
}

// const Input = styled.input`
// ${tw`form-input w-full`}
// `;
const InputLabel = styled.label`
${tw`block text-sm font-medium mb-1`}
`;
const SkillLabel = styled.label`
${tw`inline-block bg-pale px-6 py-2 text-sm text-primary-500 font-semibold leading-6 rounded-lg`}
`;
const Textarea = styled.textarea(inputStyles)

const Dialog = styled(ReachDialog)({
  maxWidth: '450px',
  borderRadius: '3px',
  paddingBottom: '3.5em',
  boxShadow: '0 10px 30px -5px rgba(0, 0, 0, 0.2)',
  margin: '20vh auto',
  [mq.small]: {
    width: '100%',
    margin: '10vh auto',
  },
})

// const FormGroup = styled.div({
//   display: 'flex',
//   flexDirection: 'column',
// })

function FullPageSpinner() {
  return (
    <div
      css={{
        fontSize: '4em',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner />
    </div>
  )
}

// const Link = styled(RouterLink)({
//   color: colors.primary,
//   ':hover': {
//     color: colors.primaryDarker,
//     textDecoration: 'underline',
//   },
// })


const Link = styled(RouterLink)(({ disabled = false }) => [
  !disabled ?
    tw`text-primary-600 hover:underline hover:text-primary-800` :
    tw`text-dark-grey hover:no-underline pointer-events-none cursor-not-allowed`
])
// const Link = styled(RouterLink)({
//   ...tw`text-primary-600 hover:underline hover:text-primary-800`
// })
// const Link = styled.RouterLink`
// ${tw`text-primary-500 hover:underline hover:text-primary-800 `}
// `;

const errorMessageVariants = {
  stacked: { display: 'block' },
  inline: { display: 'inline-block' },
}

function ErrorMessage({ error, variant = 'stacked', ...props }) {
  return (
    <div
      role="alert"
      css={[{ color: colors.danger }, errorMessageVariants[variant]]}
      {...props}
    >
      <span>There was an error: </span>
      <pre
        css={[
          { whiteSpace: 'break-spaces', margin: '0', marginBottom: -5 },
          errorMessageVariants[variant],
        ]}
      >
        {error.message}
      </pre>
      <div className='my-5'>
        <Link to={"/"} className="text-center font-medium hover:no-underline">
          <button
            className="btn bg-primary-500 hover:bg-primary-600 text-white">
            Go back
          </button>
        </Link>
      </div>
    </div>
  )
}

function Banner({
  children,
  className,
  type,
  open,
  setOpen
}) {

  const typeIcon = (type) => {
    switch (type) {
      case 'warning':
        return (
          <svg className="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3" viewBox="0 0 16 16">
            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
          </svg>
        );
      case 'error':
        return (
          <svg className="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3" viewBox="0 0 16 16">
            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z" />
          </svg>
        );
      case 'success':
        return (
          <svg className="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3" viewBox="0 0 16 16">
            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z" />
          </svg>
        );
      default:
        return (
          <svg className="w-4 h-4 shrink-0 fill-current opacity-80 mt-[3px] mr-3" viewBox="0 0 16 16">
            <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z" />
          </svg>
        );
    }
  };

  const typeColor = (type) => {
    switch (type) {
      case 'warning':
        return 'bg-amber-500';
      case 'error':
        return 'bg-rose-400';
      case 'success':
        return 'bg-emerald-500';
      default:
        return 'bg-indigo-500';
    }
  };

  return (
    <>
      {open &&
        <div className={className}>
          <div className={`my-6 px-4 py-2 rounded-sm text-sm text-white ${typeColor(type)}`}>
            <div className="flex w-full justify-between items-start">
              <div className="flex">
                {typeIcon(type)}
                <div className="font-medium">
                  {children}
                </div>
              </div>
              <button className="opacity-70 hover:opacity-80 ml-3 mt-[3px]" onClick={() => setOpen(false)}>
                <div className="sr-only">Close</div>
                <svg className="w-4 h-4 fill-current">
                  <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
}


function FullPageErrorFallback({ error }) {
  return (
    <div
      role="alert"
      css={{
        color: colors.danger,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p>Uh oh... There's a problem. Try refreshing the app.</p>
      <pre>{error.message}</pre>
    </div>
  )
}

export {
  FullPageErrorFallback,
  ErrorMessage,
  CircleButton,
  BookListUL,
  Spinner,
  Button,
  Input,
  InputLabel,
  Textarea,
  Dialog,
  FileInput,
  // FormGroup,
  SkillLabel,
  SidebarNavItem,
  Banner,
  FullPageSpinner,
  Link,
}
