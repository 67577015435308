export const base = 'white'
export const text = '#434449'
export const gray = '#f1f2f7'
export const gray10 = '#f1f1f4'
export const gray20 = '#e4e5e9'
export const gray80 = '#6f7077'
export const indigo = '#3f51b5'
export const indigoDarken10 = '#364495'
export const indigoLighten80 = '#b7c1f8'
export const lightBlue = '#DBEAFE'
export const yellow = '#ffc107'
export const green = '#4caf50'
export const danger = '#ef5350'
export const remove = '#DC2626'
export const orange = 'orange'
// ===============
export const primary = '#0075B8'
export const sidebar = '#002D4D'
export const fade = '#F1F5F9'
export const disabledInput = '#F8FAFC'
export const fadedText = '#64748B'
export const anchorText = '#475569'
export const disabledText = '#94A3B8'
export const primaryDarker = '#006DB1'

