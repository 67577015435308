import * as React from 'react'
import './bootstrap'
import { GlobalStyles } from 'twin.macro'

import { useAuth } from './context/auth-context'
import { FullPageSpinner } from './components/lib'

const AuthenticatedApp = React.lazy(() =>
  import(/* webpackPrefetch: true */ './authenticated-app'),
)
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'))

function App() {
  const { user } = useAuth()
  return (
    <>
      {/* <GlobalStyles /> */}
      <React.Suspense fallback={<FullPageSpinner />}>
        {user && !user.onboarding ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </React.Suspense>
    </>
  )
}

export { App }
