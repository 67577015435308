/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/react'

import * as React from 'react'
import * as auth from '../auth-provider'
import apiClient, { internalAxiosClient } from '../utils/api-client'
import { useAsync } from '../utils/hooks'
// import { setQueryDataForBook } from '../utils/books'
import { FullPageSpinner, FullPageErrorFallback } from '../components/lib'
import { useQueryClient } from '@tanstack/react-query'

async function bootstrapAppData(queryClient) {
  let user = null
  // let accessToken;
  // let userId;
  const isPersistLogin = await auth.getPersistanceStatus()
  const isEligible = await auth.getUserLastActivityEligibility()
  if (!isPersistLogin && !isEligible) await auth.logout().catch(console.log)
  else if (isPersistLogin || isEligible) {
    try {
      const data = await auth.refreshTokens()
      user = { ...data.user, accessToken: data.accessToken }
      // accessToken = user?.accessToken?.token
      // userId = user?.id
    } catch (error) {
      return null
    }
    // if (accessToken && userId) {
    //   try {
    //     const config = {
    //       headers: {
    //         'Authorization': `Bearer ${accessToken}`
    //       }
    //     }
    //     // TODO: revisit here the bootstrapping logic
    //     const data = await apiClient(`/users/${userId}`, config)
    //     // queryClient.setQueryData(['list-items'], data.listItems, {
    //     //   staleTime: 5000,
    //     // })
    //     // for (const listItem of data.listItems) {
    //     //   setQueryDataForBook(queryClient, listItem.book)
    //     // }
    //     queryClient.setQueryData(['user-data'], data.listItems, {
    //       staleTime: 5000,
    //     })
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
  }
  return user
}
const AuthContext = React.createContext()
AuthContext.displayName = 'AuthContext'

function AuthProvider(props) {
  const {
    data: user,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
    setError,
    reset
  } = useAsync()

  const queryCache = useQueryClient()

  React.useEffect(() => {
    const appDataPromise = bootstrapAppData(queryCache)
    run(appDataPromise)
  }, [queryCache, run])

  const login = React.useCallback(
    form => auth.login(form).then(({ user, ...data }) => setData({ ...user, ...data })),
    [setData],
  )
  const register = React.useCallback(
    form => auth.register(form).then(({ user, ...data }) => setData({ ...user, ...data, onboarding: true })),
    [setData],
  )
  const completeOnboarding = React.useCallback(
    () => setData((prev) => ({ ...prev, onboarding: false })),
    [setData],
  )
  const forgotPassword = React.useCallback(
    form => auth.forgotPassword(form).then(() => setData(null)),
    [setData],
  )
  const resetPassword = React.useCallback(
    form => auth.resetPassword(form).then(() => setData(null)),
    [setData],
  )
  const verifyGuestInvitation = React.useCallback(
    form => auth.verifyGuestInvitation(form).then((data) => data),
    [],
  )
  const refreshTokens = React.useCallback(
    () => auth.refreshTokens().then((data) => {
      setData({ ...data.user, accessToken: data.accessToken })
      return data
    }),
    [setData],
  )

  const logout = React.useCallback(() => {
    auth.logout()
    queryCache.clear()
    setData(null)
  }, [queryCache, setData]);

  const sessionTimeout = React.useCallback(() => {
    setError({ message: 'You have been inactive for a long period, please reauthenticate.', status: 408 })
  }, [setError])

  const updateUser = React.useCallback(
    updatedUser => setData({ ...updatedUser }),
    [setData],
  )

  const value = React.useMemo(
    () => ({ user, login, logout, register, refreshTokens, forgotPassword, resetPassword, verifyGuestInvitation, sessionTimeout, error, reset, completeOnboarding, updateUser }),
    [user, login, logout, register, refreshTokens, forgotPassword, resetPassword, verifyGuestInvitation, sessionTimeout, error, reset, completeOnboarding, updateUser],
  )

  // if (isLoading || isIdle) {
  if (isLoading) {
    return <FullPageSpinner />
  }

  if (isError) {
    if (error.status === 408) return <AuthContext.Provider value={value} {...props} />
    return <FullPageErrorFallback error={error} />
  }

  if (isSuccess || isIdle) {
    return <AuthContext.Provider value={value} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

function useClient() {
  const { user, refreshTokens } = useAuth()
  const token = user?.accessToken?.token
  React.useEffect(() => {

    const requestIntercept = internalAxiosClient.interceptors.request.use(
      config => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      }, (error) => Promise.reject(error)
    );

    const responseIntercept = internalAxiosClient.interceptors.response.use(
      response => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const data = await refreshTokens();
          prevRequest.headers['Authorization'] = `Bearer ${data?.accessToken?.token}`;
          return internalAxiosClient(prevRequest);
        }
        return Promise.reject(error);
      }
    );
    return () => {
      internalAxiosClient.interceptors.request.eject(requestIntercept);
      internalAxiosClient.interceptors.response.eject(responseIntercept);
    }
  }, [token, refreshTokens])

  return internalAxiosClient;
}

export { AuthProvider, useAuth, useClient }
