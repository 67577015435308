import * as React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClientProvider } from './query-client'
import { AuthProvider } from './auth-context'
import { SnackbarProvider } from 'notistack';

function AppProviders({ children }) {
  return (
    <QueryClientProvider>
      <Router>
        <AuthProvider>
          <SnackbarProvider variant='warning' className={'bg-primary-400'} maxSnack={3}>
            {children}
          </SnackbarProvider>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  )
}

export { AppProviders }
