import axios from 'axios';
const apiURL = process.env.REACT_APP_API_URL

export default axios.create({
  baseURL: apiURL,
});

export const internalAxiosClient = axios.create({
  baseURL: apiURL,
  // headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});