async function getPersistanceStatus() {
  return JSON.parse(window.localStorage.getItem(process.env.REACT_APP_PERSISTANCE_KEY))
}

async function getUserLastActivityEligibility() {
  // TODO: this time must be the same as idle time and at some config place like .env
  const EIGHT_HOURS = 8 * 60 * 60 * 1000;
  const lastActivityDate = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_ACTIVITY_KEY))
  return ((new Date()) - new Date(lastActivityDate)) < EIGHT_HOURS
}

// function handleUserResponse(data) {
//   window.localStorage.setItem(localStorageKey, JSON.stringify(data))
//   return data
// }

function login({ email, password }) {
  return client('login', { email, password })
}

function register({ token, ...data }) {
  return client(`register/${token}`, data?.formData, 'POST', true)
}

function verifyGuestInvitation({ token }) {
  return client(`register/${token}`, {}, 'GET')
}

function forgotPassword({ email }) {
  return client('forgot-password', { email })
}

function resetPassword({ password, token }) {
  return client(`reset-password?token=${token}`, { password })
}

function refreshTokens() {
  return client(`refresh-tokens`)
}

async function logout() {
  // return client(`logout`).then(() => window.localStorage.removeItem(localStorageKey))
  return client(`logout`)
}

// I am making my own HTTP client that is not tightly coupled to any implementation (axios, jquery, fetch etc.) (Adaptor design pattern) 
const authURL = process.env.REACT_APP_AUTH_URL

async function client(endpoint, data, method = 'POST', isFormData = false) {
  const config = {
    method: method,
    credentials: 'include',
  }
  if (!isFormData) {
    config.headers = { 'Content-Type': 'application/json' };
  } else {
    // config.headers = { 'Content-Type': 'multipart/form-data' };
  }

  if (method !== 'GET' && !isFormData) {
    config.body = JSON.stringify(data)
  } else if (method !== 'GET' && isFormData) {
    config.body = data
  }

  return window.fetch(`${authURL}/${endpoint}`, config).then(async response => {
    if (response.status === 204) {
      return { status: 204, statusCode: 'success' }
    }
    const data = await response.json()
    if (response.ok) {
      return data
    } else {
      return Promise.reject(data)
    }
  })
}

export { login, register, verifyGuestInvitation, forgotPassword, resetPassword, logout, refreshTokens, getPersistanceStatus, getUserLastActivityEligibility }
